
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import router from "@/router";
import { leadsData, ql } from '@/store/stateless/store';
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    // Dropdown1,
    // AddCustomerModal
  },
  setup() {
    const store = useStore();

    const getDashboardDateTitle = computed(() => {
      return store.getters.getDashboardDateTitle;
    });

    const bread = (e) => {
      router.push({ name: link(e) });
    };
    const link = (e) => {
      switch (e) {
        case "Customer Details":
        case "Add Lead - Car & Driver Details":
        case "Add User Details":
        case "Generate Quote PDF":
        case "Make Invoice":
          return "customer-list"; //
        case "Approved Invoices":
        case "Amend Invoice":
          return "invoice-list"; //
        case "Invoices For Approval":
          return "policy-approval"; //
        case "Declined Invoices":
          return "declined-invoice"; //
        case "Policy Assigned":
        case "Policy Details":
          return "policy-assigned"; //
        case "Policy Completed":
          return "policy-completed"; //
        case "Policy Cancellation Request":
          return "policy-cancellation-request"; //
        case "Policies Cancelled":
          return "policy-cancelled"; //
        case "Users Listing":
        case "Add User":
        case "Edit User":
          return "user-list"; //
        case "Add Promotion Code":
        case "Edit Promotion Code":
          return "promotion-code-list"; //
        default:
          return "dashboard";
      }
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    // const openQL = () => {
      
    // }

    const quickleadModal = ref()

    const setViewQuickLead = () => {
      // ql.isOpen = true
      store.commit(Mutations.SET_VIEW_QUICK_LEAD, true);
    };

    return {
      setViewQuickLead,
      router,
      quickleadModal,
      getDashboardDateTitle,
      bread,
      toolbarWidthFluid,
      user,
      leadsData,
      ql
    };
  }
});
