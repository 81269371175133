
import { defineComponent, computed, onMounted, watch, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
// import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
// import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
// import KTExplore from "@/layout/extras/Explore.vue";
// import LQExplore from "@/components/quotes/List-Quote.vue";
// import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    // KTCreateApp,
    // KTUserMenu,
    // KTExplore,
    // LQExplore,
    // KTDrawerMessenger,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    
    const withContainer = ref<boolean>(false)

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    const pathName = ['policy-approval', 'invoice-list', 'declined-invoice', 'manual-order-view',
    'manual-order-list', 'policy-assigned', 'policy-completed', 'policy-cancellation-request',
    'policy-cancellation-approval', 'policy-refund-approval', 'policy-cancelled', 'policy-refund-closed',
    'report-policy', 'report-agent-sales', 'report-skye-login-info', 'report-account-master', 'report-approved-invoice',
    'report-lead-by-source', 'report-closed-leads', 'report-deleted-leads', 'report-discrepancy', 'admin-report',
    'dashboard-details-report', 'al-manarah-report', 'user-list', 'promotion-code-list', 'sales-target-list', 'lead-target-list',
    'callback-requests-list', 'contact-us-list', 'quote-other-requests-list', 'quote-pet-requests-list',
    'expat-insurance-inquiries', 'marketing-spend'
    ]

    watchEffect(() => {
      const routeName = route.name?.toString() || ''
      withContainer.value = pathName.includes(routeName) ? true : false
    })

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    return {
      withContainer,
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
