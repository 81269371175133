
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
// import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import BaiNotification from "@/layout/header/partials/BaiNotification.vue";
import NotificationService from "@/core/services/car/NotificationService";
import BaiSearch from "@/layout/header/partials/BaiSearch.vue";
import QuickLinks from "@/layout/header/partials/QuickLinks.vue";
import mainSocket from "./../../main-socket";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import { ElNotification } from "element-plus";

import { Actions, Mutations } from "@/store/enums/StoreEnums";
export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    // KTUserMenu,
    BaiNotification,
    BaiSearch,
    QuickLinks
  },
  setup() {
    let notificationList = ref<Array<any>>([]);
    let paginationDetails = ref<any>();
    const SearchDomRef = ref();
    const dialogVisible = ref(false);
    // let notificationCount = ref<any>(0);
    const store = useStore();
    const router = useRouter();
    let userObj: any = {};

    const datetime = ref<any>(null);
    const respo = ref<any>([]);

    onMounted(async() => {
      getNotificationCount();

      const currentUserId = user.value.id;
      mainSocket
        .channel("channel-" + currentUserId)
        .listen("UnReadNotification", (response) => {
          //currentUserId == response.notifiedTo
          if (response.notification) {
            getNotificationCount();
          }
        });

      
    });

    const getNotificationCount = () => {
      Promise.all([NotificationService.getNotificationCount()]).then((data) => {
        console.log(data);
      });
    };

    let notificationCount = computed(() => {
      return store.getters.getUnreadNotificationCount;
    });

    const user = computed(() => {
      userObj = LocalStorageServices.getUser();
      if (userObj == null) {
        userObj = {};
        return userObj;
      }
      return JSON.parse(userObj);
    });

    const getRecentNotification = () => {
      Promise.all([
        NotificationService.getNotificationList({ per_page: 10 }, 1)
      ]).then((data) => {
        // notificationCount.value = store.getters.getUnreadNotificationCount;
        notificationList.value = store.getters.getNotification;
        paginationDetails.value = store.getters.getNotificationPaginationObj;
      });
    };
    // const popupNotification = (contentHtml, isNotifStay) => {
    //   ElNotification({
    //     customClass: isNotifStay? "notification-skye":"notification-success",
    //     title: "Notification",
    //     message: contentHtml,
    //     duration: isNotifStay? 10000:2500,
    //     type: isNotifStay? "info":"success",
    //     dangerouslyUseHTMLString: true
    //   });
    // };
    const signOut = async () => {
      const user = JSON.parse(window.localStorage.getItem('user') || '')
      const payload = { user_id: user.id }
      await store.dispatch(Actions.LOGOUT, payload);
      store.commit(Mutations.PURGE_AUTH);
      // ElNotification.closeAll();
      // window.location.href = '/#/sign-in'
    };
    // ElNotification.closeAll()


    const switchRole = async(e)=>{
        // e.preventDefault()
        dialogVisible.value = false
        const data = await store.dispatch(Actions.SWITCH_ROLE)
        // const user = store.getters.currentUser;
        window.localStorage.setItem('uid', data.data.data.user.id);
        window.localStorage.setItem('user', JSON.stringify(data.data.data.user));
        await store.dispatch(Actions.GET_ROUTE_LIST, { type: "car" })
        await router.push({ name: 'dashboard' }).then(() => router.go(0))
        // window.location.reload()
    }


    return {
      datetime,
      SearchDomRef,
      getRecentNotification,
      notificationCount,
      notificationList,
      paginationDetails,
      getNotificationCount,
      user,
      // popupNotification,
      signOut,
      switchRole,
      dialogVisible
    };
  }
});
